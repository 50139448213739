import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import RouterLink from '../shared/RouterLink'
import { fade, makeStyles } from '@material-ui/core/styles'
import {findColorByIndex} from '../themeColors'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { ForceHeadlineDarkTheme, ForceLightTheme } from '../../reused-libraries/materialTheme'
import BrandLogo from '../common/BrandLogo'

const navBgColorDark = "#181818";
const navBgColorLight = "#fbfbfb";

const useHeaderNavigationStyles = makeStyles(theme => {
	return {
		navWrapper: {
			position: "relative",
		},
		navMain: {
			position: "relative",
			width: "100%",
			"& $navBrandName": {
				"& a, a:hover": {
					margin: "0",
					fontWeight: "200",
					textDecoration: "none",
				}
			},
			"& a, a:hover": {
				margin: "0.75rem 0 0.75rem " + theme.spacing(3) + "px",
				display: "inline-block",
			},
		},
		navOverlayContainer: {
			position: "absolute",
			top: 0,
			left: 0,
			zIndex: 1,
			"&$navigationBg": {
				background: props => fade(props.isDark ? navBgColorDark : navBgColorLight, 0.7),
			},
		},
		navOverlayContainerWideScreen: {
			[theme.breakpoints.up('sm')]: {
				position: "absolute",
				top: 0,
				left: 0,
				zIndex: 1,
				"&$navigationBg": {
					background: props => fade(props.isDark ? navBgColorDark : navBgColorLight, 0.7),
				},
			},
		},
		navigationBg: {
			background: props => props.isDark ? "linear-gradient(10deg, " + findColorByIndex(1) + ", " + findColorByIndex(0) + ")" : navBgColorLight,
		},
		mainContainer: {
			[theme.breakpoints.up('md')]: {
				paddingLeft: theme.spacing(6),
			},
		},
		navBrandName: {
			height: "40px",
			marginTop: theme.spacing(1.5),
			marginBottom: theme.spacing(1.5),
		},
		navigationBgTransparent: {
			backgroundColor: "transparent",
		},
	};
}, {name: "HeaderNavigation"});

export default function HeaderNavigation(props) {
	let {logoVariant, bg, alwaysOverlay, overlayWhenWide} = props;

	let isDark = logoVariant === "darkBg" || logoVariant === "whiteout";
	const HeaderTheme = isDark ? ForceHeadlineDarkTheme : (bg ? ForceLightTheme : React.Fragment);

	const classes = useHeaderNavigationStyles({isDark});

	return (
		<HeaderTheme>
			<div className={classes.navWrapper}>
				<Paper
					component="nav"
					square
					className={cn(
						classes.navMain,
						alwaysOverlay ? classes.navOverlayContainer : {[classes.navOverlayContainerWideScreen]: overlayWhenWide},
						bg ? classes.navigationBg : classes.navigationBgTransparent,
					)}
					elevation={!!bg ? 3 : 0}
				>
					<div className={cn("container-fluid", classes.mainContainer)}>
						<Grid container className="align-items-center">
							<Grid item xs="auto" lg className="d-flex align-items-center justify-content-between">
								<div className="order-2 order-lg-1">
									<div className={cn(classes.navBrandName)}>
										<RouterLink to="/">
											<BrandLogo eager logoVariant={logoVariant}/>
										</RouterLink>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>
				</Paper>
			</div>
		</HeaderTheme>
	);
}
HeaderNavigation.propTypes = {
	logoVariant: PropTypes.oneOf(['normal', 'darkBg', 'whiteout']),
	/** true to include a background behind the header, or false for a transparent header */
	bg: PropTypes.bool,
	/** true for a transparent and absolutely positioned nav regardless of screen width */
	alwaysOverlay: PropTypes.bool,
	/** true for a transparent and absolutely positioned nav when the screen is wider */
	overlayWhenWide: PropTypes.bool,
};
