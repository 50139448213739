import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

export default function PageInfo(props) {
	let {title, description} = props;
	return (
		<>
			<Helmet defer={false}>
				<title>{title}</title>
				{description && (
					<meta name="description" content={description}/>
				)}
			</Helmet>
		</>
	);
}
PageInfo.propTypes = {
	/** The HTML page title */
	title: PropTypes.string.isRequired,
	/** The HTML meta content for name "description". Most every page should have this, except for e.g. the 404 page */
	description: PropTypes.string,
};
