import React from 'react'
import PropTypes from 'prop-types'
import { useSiteMetadata } from '../SiteData'
import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles'
import {findColorByIndex, radialGradientStyle} from '../themeColors'

const useFooterStyles = makeStyles(theme => ({
	footer: {
		...radialGradientStyle(theme, findColorByIndex(0), findColorByIndex(1)),
		color: "#fff",
		padding: "2rem 1rem",
		"& > *:not(:last-child)": {
			marginBottom: "1rem",
		},
		[theme.breakpoints.down('sm')]: {
			"& li": {
				paddingTop: "0.4rem",
				paddingBottom: "0.9rem",
			},
		},
		"& a": {
			color: "#fbfbfb",
		},
	},
	sitemap: {
		fontSize: "0.9em",
		padding: "0 3rem",
		"& > *": {
			padding: "0 3rem !important",
		},
		"& h4": {
			fontSize: "1.2em",
		},
		"& ul": {
			listStyleType: "none",
			paddingLeft: "0",
		},
	},
	footerContent: {
		[theme.breakpoints.down('sm')]: {
			paddingTop: "2rem",
			paddingBottom: "1.6rem",
		}
	},
	legalFooterText: {
		textAlign: "center",
		maxWidth: "1000px",
		fontSize: "0.8em",
		color: "#d2d2d2",
	},
}), {name: "Footer"});

export default function FooterNavigation(props) {
	let {landingMode, children} = props;
	let {copyright} = useSiteMetadata();
	const classes = useFooterStyles();
	return (
		<div className={classes.footer}>
			<footer className="container-fluid">
				<div className={cn(classes.legalFooterText, "mx-auto", !!landingMode ? "" : "mt-5")}>
					<p>{copyright}</p>
				</div>
			</footer>
			{children}
		</div>
	);
}
FooterNavigation.propTypes = {
	landingMode: PropTypes.bool,
	children: PropTypes.any,
};
