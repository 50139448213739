import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "gatsby"
import MLink from "@material-ui/core/Link";

export default class RouterLink extends React.Component {
	constructor(props) {
		super(props);
		this.handleLink = this.handleLink.bind(this);
	}

	handleLink(evt, path) {
		let {onClick} = this.props;

		if (onClick) {
			onClick(evt);
		}
/*
		if (!evt.defaultPrevented) {
			if (!evt.metaKey && !evt.ctrlKey) {
				// We're not opening in a new tab
				evt.preventDefault();
				history.push(path);
			}
		}
*/
	}

	render() {
		const {to, suppressDecoration, className, color = 'primary', children} = this.props;
		let pathname = to;
		let search = "";
		if (to.pathname) {
			pathname = to.pathname;
			search = to.search || "";
		}

		return (
			<MLink component={Link} underline={!suppressDecoration ? "always" : "hover"} color={color} to={pathname + search} className={className} onClick={evt => { this.handleLink(evt, to); }}>
				{children}
			</MLink>
		);
	}
}
RouterLink.propTypes = {
	/** Either an object containing a "pathname" or a string that is the pathname */
	to: PropTypes.any.isRequired,
	/** true to hide normal link decoration */
	suppressDecoration: PropTypes.bool,
	color: PropTypes.oneOf([
		'default',
		'error',
		'inherit',
		'primary',
		'secondary',
		'textPrimary',
		'textSecondary',
	]),
	/** Optional classes to add */
	className: PropTypes.string,
	onClick: PropTypes.func,
	/** The link content */
	children: PropTypes.any.isRequired,
};
