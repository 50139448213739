import React from 'react'
import cn from 'classnames'
import { Helmet } from 'react-helmet'
import HeaderNavigation from './page/HeaderNavigation'
import FooterNavigation from './page/FooterNavigation'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { findColorByIndex } from './themeColors'

const useAppContentStyles = makeStyles(theme => ({
	aboveTheFold: {
		overflow: "hidden",
		[theme.breakpoints.up('md')]: {
			minHeight: "100vh",
		},
		display: "flex",
		flexDirection: "column",

		// Image source: https://unsplash.com/photos/v1nLZZfQ-d8
		// background: 'linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)),'
		// 		+ ' url("../images/title-bg.jpeg") 100% 0% / cover no-repeat scroll content-box padding-box #171717',

		// backgroundColor: "#7f5a83",
		// backgroundImage: "linear-gradient(315deg, #7f5a83 0%, #0d324d 74%)",

		backgroundColor: findColorByIndex(2),  // fallback for old browsers
		background: "linear-gradient(to bottom right, " + findColorByIndex(1) + ", " + findColorByIndex(2) + ")", // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+
	},
	invisible: {
		visibility: "hidden",
	},
}), {name: "AppContent"});

export default function AppLayout(props) {
	return (
		<>
			<Helmet defer={false}>
				{/* Google Search Console verification */}
				<meta name="google-site-verification" content="6YQP4fAswNl2ytU6FGCHfCfl_mVpgfebdK8ho0OsX8g" />
			</Helmet>
			<AppContent {...props}/>
		</>
	);
}
AppLayout.propTypes = {
	logoVariant: PropTypes.oneOf(['normal', 'darkBg', 'whiteout']),
	/** true to limit the amount of links in the header and footer, such as for a landing page */
	landingMode: PropTypes.bool,
	/** Called with no params to render items "above the fold" in place of the header. If not provided, the header navigation is rendered */
	renderAboveTheFold: PropTypes.func,
	/** true for a transparent and absolutely positioned header nav when the screen is wider */
	overlayNavWhenWide: PropTypes.bool,
	/** true to exclude the header nav when there is no above the fold content */
	excludeHeaderNav: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node,
}

function AppContent(props) {
	let {logoVariant = 'whiteout', landingMode, renderAboveTheFold, overlayNavWhenWide, excludeHeaderNav, className, children} = props;
	const classes = useAppContentStyles();

	return (
		<div className="d-flex flex-column" style={{minHeight: "100vh"}}>
			<div style={{flex: "auto"}} className="d-flex flex-column">
				<div className={cn({[classes.aboveTheFold]: !!renderAboveTheFold})}>
					{renderAboveTheFold ? (
						renderAboveTheFold()
					) : !excludeHeaderNav && (
						<HeaderNavigation logoVariant={logoVariant} landingMode={landingMode} bg overlayWhenWide={overlayNavWhenWide}/>
					)}
				</div>
				<main style={{flex: "auto"}} className={className}>
					{children}
				</main>
				<div>
					<FooterNavigation landingMode={landingMode}/>
				</div>
			</div>

		</div>
	);
}
AppContent.propTypes = {
	logoVariant: PropTypes.oneOf(['normal', 'darkBg', 'whiteout']),
	/** true to limit the amount of links in the header and footer, such as for a landing page */
	landingMode: PropTypes.bool,
	/** Called with no params to render items "above the fold" in place of the header. If not provided, the header navigation is rendered */
	renderAboveTheFold: PropTypes.func,
	/** true for a transparent and absolutely positioned header nav when the screen is wider */
	overlayNavWhenWide: PropTypes.bool,
	/** true to exclude the header nav when there is no above the fold content */
	excludeHeaderNav: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node,
}
